import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Button,
    Divider,
    Card,
    CardContent,
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    DialogActions
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import { backArrow, uploadedFile, deleteComp } from "./assets";
// Customizable Area End

import ViewCompetencyController, {
    Props,
} from "./ViewCompetencyController";

export default class ViewCompetency extends ViewCompetencyController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    listingCompetencies = () => {
        return (
            <Wrapper>
                <Box className="viewCompMainBox">
                    <Grid container justifyContent="center" className="viewCompContainer" >
                        <Grid item xs={12} className="companyCardItem">
                            <Card className="viewCompDescBox">
                                <CardContent className="cardContentPadding">
                                    <Grid container>
                                        <Grid item xs={12} className="orgDetailPadding">
                                            <Typography className="cardTitle">
                                                Job Competency
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3.5} className="contentPadding">
                                        <Grid item xs={12} md={6}>
                                            {this.showCompetencyType()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.showJobTitle()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.showDescription()}
                                        </Grid>
                                        {this.state.viewCompetency?.data?.attributes.file_filename && <>
                                            <Grid item xs={12}><Divider /></Grid>

                                            <Grid item xs={12}>
                                                {this.jdTemplates()}
                                            </Grid>
                                        </>
                                        }
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper >
        )
    }

    deleteCompetencyDialog = () => {
        return (
            <Dialog
                open={this.state.openDeleteComp}
                onClose={this.handleCloseDeleteComp}
                PaperProps={{
                    sx: {
                        direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                        borderRadius: "8px 8px 32px 8px",
                        overflow: 'hidden',
                    }
                }}
            >
                <DialogTitle>
                    <Wrapper>
                        <Typography className="successCompDialogTitle">
                            Delete Details
                        </Typography>
                        <IconButton
                            aria-label="close"
                            data-test-id="close_delete_competency"
                            onClick={this.handleCloseDeleteComp}
                            className="deleteCompDialogClose"
                            sx={{
                                right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                                left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Wrapper>
                </DialogTitle>
                <Divider sx={{ color: "#E2E8F0" }} />
                <DialogContent>
                    <Wrapper>
                        <Box className="deleteCompImageBox">
                            <img src={deleteComp.default} alt="Success Image" height={144} width={144} />
                        </Box>
                    </Wrapper>
                    <Typography
                        sx={{
                            fontSize: "20px",
                            fontWeight: 700,
                            fontFamily: "Urbanist",
                            padding: {
                                xs: "14px 14px",
                                sm: "14px 64px"
                            },
                            lineHeight: "28px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        Are you sure you want to delete this Organisational competency?
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            lineHeight: "26px",
                            fontFamily: "Urbanist",
                            padding: "0px 68px",
                            fontSize: "18px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        You won't be able to access it again.
                    </Typography>

                </DialogContent>
                <Divider />
                <DialogActions>
                    <Wrapper>
                        <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "deleteButtonsBox" : "deleteButtonsBoxArabic"}`}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={this.handleCloseDeleteComp}
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "cancelCompButton" : "cancelButtonArabic"}`}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={this.deleteCompetencyApiCall}
                                data-test-id="delete_comp"
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "deleteCompButton" : "deleteButtonArabic"}`}
                            >
                                Delete
                            </Button>
                        </Box>
                    </Wrapper>
                </DialogActions>
            </Dialog>
        )
    }

    showCompetencyType = () => {
        return (
            <Wrapper>
                <Typography className="compLabel">
                    Competency Type <span>*</span>
                </Typography>
                <Typography className="compDropDownStyle">
                    {this.state.viewCompetency?.data?.attributes.competency_type}
                </Typography>
            </Wrapper>
        )
    }

    showJobTitle = () => {
        return (
            <Wrapper>
                <Typography className="compLabel">
                    Job Title <span>*</span>
                </Typography>
                <Typography className="compDropDownStyle">
                    {this.state.viewCompetency?.data?.attributes.job_title}
                </Typography>
            </Wrapper>
        )
    }

    showDescription = () => {
        return (
            <Wrapper>
                <Typography className="compLabel">
                    Description <span>*</span>
                </Typography>
                <Typography className="orgCultureInputField">
                    {this.state.viewCompetency?.data?.attributes.description.split('\n').map((text, index) => (
                        <React.Fragment key={index}>
                            {text}
                            <br />
                        </React.Fragment>
                    ))}
                </Typography>
            </Wrapper>
        )
    }

    compJdImageGrid = () => {
        return (
            <Grid item xs={12} sm={2} md={1.5} className="jdCompInnerGrid">
                <img
                    src={uploadedFile.default}
                    alt="upload Image"
                    data-test-id="upload_image"
                />
            </Grid>
        )
    }

    compFileNameAndSize = () => {
        return (
            <Grid item xs={12} sm={9} md={9.5} sx={{
                flexDirection: "column",
                display: "flex",
                textAlign: {
                    xs: 'center',
                    sm: 'left',
                },
                alignItems: {
                    xs: 'center',
                    sm: 'flex-start',
                },
            }}>
                <Typography className="jdCompUploadText">
                    {this.state.viewCompetency?.data?.attributes.file_filename}
                </Typography>
                <Typography className="jdCompUploadSubText">
                    {this.state.viewCompetency?.data?.attributes.file_size}KB
                </Typography>
            </Grid>
        )
    }

    jdTemplates = () => {
        return (
            <Wrapper>
                <Typography className="compLabel">
                    Uploaded Document <span>*</span>
                </Typography>
                <Box className="jdCompMainBox">
                    <Grid container alignItems={"center"} spacing={1}>
                        {this.compJdImageGrid()}

                        {this.compFileNameAndSize()}
                    </Grid>
                </Box>
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Competencies" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={11}>
                            <Grid container alignItems={"center"} spacing={1}>
                                <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}>
                                    <img src={backArrow.default}
                                        alt="Back Arrow"
                                        className="backArrowImg"
                                        data-test-id="back_to_dashboard"
                                        onClick={this.navigateToDashboard}
                                    />
                                </Grid>
                                <Grid item xs={10.5} sm={5} md={5.2} lg={5.5}>
                                    <Typography className="jobTitle">
                                        Job Competency
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={6} lg={5} className="editJobMainBox">
                                            <Button
                                                className="deleteButton"
                                                data-test-id="delete_competency"
                                                onClick={this.deleteCompetency}
                                            >
                                                <Typography className="deleteText">
                                                    Delete
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={5} className="editJobMainBox">
                                            <Button
                                                className="editJobeButton"
                                                data-test-id="edit_job_competency"
                                                onClick={this.EditJobCompetency}
                                            >
                                                <Typography className="editJobText">
                                                    Edit Job Competency
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                            {this.listingCompetencies()}
                        </Grid>
                        {this.deleteCompetencyDialog()}
                    </Grid>
                </MainWrapper >
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    width: "100%",
    "& .viewCompMainBox": {
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    "& .viewCompContainer": {
        width: "100%",
        padding: "0px 8px",
        scrollbarWidth: "none",
        overflowX: "auto",
        scrollSnapType: "x mandatory",
        scrollBehavior: "smooth",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    "& .compDropDownStyle": {
        lineHight: "24px",
        backgroundColor: 'white',
        fontSie: '16px',
        fontWeight: 700,
        fontFamily: 'Urbanist',
        padding: '10px 0px',
        width: '100%',
        color: "#475569"
    },
    "& .deleteButtonsBox": {
        display: "flex",
        margin: "16px 0px",
        paddingRight: "16px",
        justifyContent: "flex-end"
    },
    "& .deleteButtonsBoxArabic": {
        paddingLeft: "16px",
        display: "flex",
        justifyContent: "flex-end",
        margin: "16px 0px"
    },
    "& .contentPadding": {
        padding: "30px 56px 0px 35px"
    },
    "& .cancelCompButton": {
        fontFamily: "Urbanist",
        height: "56px",
        padding: "16px 24px",
        fontWeight: 700,
        width: "30%",
        borderRadius: "8px",
        marginLeft: "16px",
        fontSize: "16px",
        gap: "8px",
        opacity: "0px",
        background: "#F1F5F9",
        textTransform: "none",
        color: "#000"
    },
    "& .cancelButtonArabic": {
        borderRadius: "8px",
        height: "56px",
        gap: "8px",
        fontFamily: "Urbanist",
        padding: "16px 39.5px 16px 36.5px",
        marginRight: "16px",
        background: "#F1F5F9",
        textTransform: "none",
        opacity: "0px",
        fontSize: "16px",
        fontWeight: 700,
        width: "120px",
        color: "#000"
    },
    "& .deleteCompButton": {
        height: "56px",
        padding: "16px 24px",
        fontWeight: 700,
        width: "30%",
        borderRadius: "8px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        gap: "8px",
        opacity: "0px",
        marginLeft: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .deleteButtonArabic": {
        height: "56px",
        gap: "8px",
        fontFamily: "Urbanist",
        padding: "16px 39.5px 16px 36.5px",
        marginRight: "16px",
        background: "#044352",
        textTransform: "none",
        borderRadius: "8px",
        opacity: "0px",
        fontSize: "16px",
        fontWeight: 700,
        width: "120px",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .successCompDialogTitle": {
        fontFamily: "Urbanist",
        color: "#1E1E1E",
        fontSize: "24px",
        lineHeight: "32px",
        fontWeight: 700,
        margin: "8px 16px"
    },
    "& .viewCompDescBox": {
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        margin: "60px 5vw",
        width: "65vw",
        "@media(max-width:900px)": {
            width: "80vw"
        },
    },
    "& .cardContentPadding": {
        padding: "0px 0px 42px 0px"
    },
    "& .deleteCompImageBox": {
        display: "flex",
        padding: "10px 0px",
        justifyContent: "center"
    },
    "& .orgCultureInputField": {
        fontSie: "16px",
        fontFamily: 'Urbanist',
        fontWeight: 400,
        lineHight: "24px",
        width: '100%',
        color: "#475569",
        "&:focus": {
            borderColor: "#CBD5E1",
            outline: "none",
        }
    },
    "& .deleteCompDialogClose": {
        top: 16,
        position: 'absolute',
        color: "#334155"
    },
    "& .cardTitle": {
        color: "#0444352",
        fontFamily: "Urbanist",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "24px"
    },
    "& .dividerPadding": {
        padding: "0px 56px 0px 35px"
    },
    "& .jdCompMainBox": {
        borderRadius: "8px",
        display: "flex",
        border: "1px solid #CBD5E1",
        alignItems: "center",
        padding: "20px 5px"
    },
    "& .compLabel": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        linHheight: "24px",
        color: "#344054",
        marginBottom: "10px"
    },
    "& .jdCompUploadSubText": {
        fontWeight: 400,
        fontFamily: "Urbanist",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#64748B"
    },
    "& .jdCompInnerGrid": {
        display: "flex",
        justifyContent: "center"
    },
    "& .jdCompUploadText": {
        lineHeight: "24px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 400,
        color: "#0F172A"
    },
})
export const MainWrapper = styled(Box)({
    width: "100%",
    marginTop: "42px",
    paddingBottom: "30px",
    display: "flex",
    justifyContent: "center",
    "& .backArrowImg": {
        paddingRight: "12px"
    },
    "& .jobTitle": {
        fontWeight: 600,
        fontSize: "25px",
        lineHeight: "30px",
        fontFamily: "Urbanist",
        color: "#000104",
        marginLeft: "12px"
    },
    "& .editJobMainBox": {
        display: "flex",
        justifyContent: "flex-end",
        "@media(max-width:600px)": {
            justifyContent: "flex-start",
            marginTop: "10px"
        }
    },
    "& .deleteButton": {
        height: "58px",
        padding: "10px 25px",
        borderRadius: "4px",
        width: "100%",
        border: "2px solid #94A3B8",
        "&:hover": {
            background: "none"
        },
        "@media(max-width:599px)": {
            width: "60%"
        }
    },
    "& .orgDetailPadding": {
        padding: "30px 56px 30px 35px"
    },
    "& .deleteText": {
        fontFamily: "Urbanist",
        fontWeight: 700,
        lineHeight: "24px",
        fontSize: "16px",
        color: "#044352",
        textTransform: "none"
    },
    "& .editJobeButton": {
        width: "100%",
        padding: "10px 25px",
        borderRadius: "4px",
        height: "58px",
        border: "1px solid #94A3B8",
        background: "#044352",
        "@media(max-width:599px)": {
            width: "60%"
        }
    },
    "& .editJobText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: "none"
    },
})
// Customizable Area End