import React from "react";
// Customizable Area Start    
import {
    Box,
    Grid,
    styled,
    Typography,
    Card,
    CardContent,
    Divider,
    Input,
    Select,
    InputBase,
    MenuItem,
    Button
} from "@mui/material";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { backArrow } from "./assets";
export const configJSON = require("./config");
// Customizable Area End

import DictionaryCreationController, {
    Props,
} from "./DictionaryCreationController";

export default class DictionaryCreation extends DictionaryCreationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    competencyName = () => {
        const error = this.state.saveDicClicked && !this.state.competencyName && configJSON.errorText;
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="dicCardInputLabel">
                        Competency Name <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <Input
                    data-test-id="competency_name"
                    className={`${error ? "errorClass" : ""} dicEnterInputField`}
                    disableUnderline
                    placeholder="Enter the competency name"
                    value={this.state.competencyName}
                    onChange={this.handleCompetencyNameChange}
                    autoComplete="off"
                />
            </Wrapper>
        )
    }

    competencyType = () => {
        const error = this.state.saveDicClicked && !this.state.selectedCompetencyType && configJSON.errorText;
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="compdInputLabel">
                        Competency Type <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <Select
                    className={`${error ? "errorClass" : ""} dropDownStyle`}
                    data-test-id="competency_type"
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    displayEmpty
                    variant="outlined"
                    value={this.state.selectedCompetencyType}
                    renderValue={
                        this.state.selectedCompetencyType
                            ? undefined
                            : () => (
                                <div className="dropDownplaceHolder">
                                    Choose the competency type
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    onChange={this.handleCompetencyTypeChange}
                    input={<InputBase />}
                >
                    {this.state.competencyType?.map((comp) => (
                        <MenuItem key={comp} value={comp}
                            sx={{
                                fontSize: "16px",
                                fontFamily: "Urbanist",
                                fontWeight: 400,
                                margin: "4px"
                            }}>
                            {comp}
                        </MenuItem>
                    ))}
                </Select>
            </Wrapper>
        )
    }

    targetLevel = () => {
        const error = this.state.saveDicClicked && !this.state.selectedTargetLevel && configJSON.errorText;
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="compdInputLabel">
                        Target Level <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <Select
                    className={`${error ? "errorClass" : ""} dropDownStyle`}
                    data-test-id="target_level"
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    value={this.state.selectedTargetLevel}
                    displayEmpty
                    variant="outlined"
                    renderValue={
                        this.state.selectedTargetLevel
                            ? undefined
                            : () => (
                                <div className="dropDownplaceHolder">
                                    Choose the Target Level
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    onChange={this.handleTargetLevelChange}
                    input={<InputBase />}
                >
                    {this.state.targetLevel?.map((targetLevel) => (
                        <MenuItem key={targetLevel} value={targetLevel}
                            sx={{
                                fontSize: "16px",
                                fontFamily: "Urbanist",
                                fontWeight: 400,
                                margin: "4px"
                            }}>
                            {targetLevel}
                        </MenuItem>
                    ))}
                </Select>
            </Wrapper>
        )
    }

    dictionaryDescription = () => {
        const error = this.state.saveDicClicked && !this.state.dictionaryDescription && configJSON.errorText
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="compdInputLabel">
                        Description <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <textarea
                    data-test-id="dic_description"
                    className={`${error ? "errorClass" : ""} dicDescInputField`}
                    placeholder="Write about your job..."
                    value={this.state.dictionaryDescription}
                    onChange={(event) => this.handleDicDescription(event)}
                />
                <Typography className="companyCultureLimit">{this.state.dictionaryDescription?.length || 0}/1000</Typography>
            </Wrapper>
        )
    }

    companyFormHeader = () => {
        return (
            <Wrapper>
                <Box className="dicItemMainBox">
                    <Grid container className="dicCardContainer" justifyContent={"flex-start"}>
                        <Grid item xs={12} className="dicCardItem">
                            <Card className="dicCardDescBox">
                                <CardContent className="dicCardContentPadding">
                                    <Grid container>
                                        <Grid item xs={12} className="dicDetailPadding">
                                            <Typography className="dicCardTitle">
                                                Competency Dictionary
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3.5} className="dicContentPadding">
                                        <Grid item xs={12}>
                                            {this.competencyName()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.competencyType()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.targetLevel()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.dictionaryDescription()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.cancelCompDictionaryBtn()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.saveCompDictionaryBtn()}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    cancelCompDictionaryBtn = () => {
        return (
            <Wrapper>
                <Button
                    className="dictionaryCancelBtn"
                    data-test-id="cancel_dictionary"
                    onClick={this.navigateToDashboard}
                >
                    <Typography className="dicCancelText">
                        Cancel
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    saveCompDictionaryBtn = () => {
        return (
            <Wrapper>
                <Button
                    className="saveDictionaryBtn"
                    data-test-id="save_dictionary"
                    onClick={this.handleSaveCompDictionary}
                >
                    <Typography className="saveCompText">
                        Save Competency Dictionary
                    </Typography>
                </Button>
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Competencies" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={11}>
                            <Grid container spacing={1} alignItems={"center"}>
                                <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}>
                                    <img src={backArrow.default}
                                        alt="Back Arrow"
                                        className="backArrowImg"
                                        data-test-id="back_to_dashboard"
                                        onClick={this.navigateToDashboard}
                                    />
                                </Grid>
                                <Grid item xs={10.5} sm={11} md={11.2} lg={11.5}>
                                    <Typography className="createDicTitle">
                                        Create Competency Dictionary
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} className="compMainHeaderGrid">
                                <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                                    {this.companyFormHeader()}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const MainWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "42px",
    paddingBottom: "30px",
    "& .backArrowImg": {
        paddingRight: "12px"
    },
    "& .createDicTitle": {
        fontSize: "25px",
        fontWeight: 600,
        fontFamily: "Urbanist",
        lineHeight: "30px",
        color: "#000104",
        marginLeft: "12px"
    },

})
export const Wrapper = styled(Box)({
    width: "100%",
    "& .dicCardDescBox": {
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        width: "55vw",
        margin: "4vh 3vw",
        "@media(max-width:900px)": {
            width: "80vw"
        },
    },
    "& .dicCardInputLabel": {
        linHheight: "22px",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 700,
        color: "#344054",
        marginBottom: "10px"
    },
    "& .dicCardItem": {
        scrollSnapAlign: "center",
        flex: "0 0 auto",
    },
    "& .dictionaryCancelBtn": {
        padding: "10px 16px 10px 16px",
        width: "100%",
        height: "58px",
        borderRadius: "4px",
        border: "1px solid #94A3B8",
        marginTop: "16px",
        "&:hover": {
            background: "none"
        }
    },
    "& .dicItemMainBox": {
        width: "100%",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
    },
    "& .dropDownStyle": {
        fontSie: '16px',
        backgroundColor: 'white',
        height: '66px',
        borderRadius: '8px',
        lineHight: "24px",
        border: '1px solid #CBD5E1',
        fontWeight: 700,
        fontFamily: 'Urbanist',
        padding: '10px 12px',
        width: '100%',
        color: "#044352"
    },
    "& .dicEnterInputField": {
        borderRadius: '8px',
        padding: '12px 16px',
        fontFamily: 'Urbanist',
        color: "#044352",
        border: '1px solid #CBD5E1',
        fontSie: '16px',
        fontWeight: 700,
        lineHight: "24px",
        height: '66px',
        width: '100%'
    },
    "& .MuiSelect-icon": {
        transform: 'none !important',
    },
    "& .dicDetailPadding": {
        padding: "30px 56px 30px 35px"
    },
    "& .dicDescInputField": {
        fontWeight: 400,
        lineHight: "24px",
        minHeight: "98px",
        height: "auto",
        borderRadius: "8px",
        boxShadow: "0px 1px 2px 0px #1018280D",
        fontSie: "16px",
        fontFamily: 'Urbanist',
        border: '1px solid #CBD5E1',
        padding: "12px 16px",
        width: '100%',
        color: "#475569",
        "&:focus": {
            borderColor: "#CBD5E1",
            outline: "none",
        }
    },
    "& .dicCardContentPadding": {
        padding: "0px 0px 42px 0px"
    },
    "& .dicContentPadding": {
        padding: "30px 56px 0px 35px"
    },
    "& .dicCardTitle": {
        fontWeight: 600,
        fontFamily: "Urbanist",
        fontSize: "20px",
        lineHeight: "24px",
        color: "#0444352"
    },
    "& .errorStyleMainbox": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .saveCompText": {
        fontWeight: 700,
        fontSize: "16px",
        color: "#FFFFFF",
        fontFamily: "Urbanist",
        lineHeight: "24px",
        textTransform: "none"
    },
    "& .saveDictionaryBtn": {
        border: "1px solid #94A3B8",
        width: "100%",
        borderRadius: "4px",
        height: "58px",
        padding: "10px 16px 10px 16px",
        background: "#044352",
        marginTop: "16px",
    },
    "& .dicCardContainer": {
        display: "flex",
        justifyContent: "flex-start",
        overflowX: "auto",
        scrollSnapType: "x mandatory",
        scrollBehavior: "smooth",
        padding: "0px 8px",
        width: "100%",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    "& .errorStyle": {
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Urbanist",
        color: "#F87171",
        fontStyle: "italic",
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .dicCancelText": {
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "Urbanist",
        lineHeight: "24px",
        color: "#000000",
        textTransform: "none"
    },
    "& .compdInputLabel": {
        marginBottom: "10px",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 700,
        linHheight: "22px",
        color: "#344054"
    },
    "& .errorClass": {
        border: '1px solid #F87171 !important',
    },
    "& .companyCultureLimit": {
        display: "flex",
        justifyContent: "flex-end",
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: "Urbanist",
    },
    "& input::placeholder, textarea::placeholder": {
        color: "grey",
        fontSize: "16px",
        fontWeight: 400
    },
    "& .dropDownplaceHolder": {
        color: "lightgrey",
        fontSize: "16px",
        fontWeight: 400
    }
})
// Customizable Area End