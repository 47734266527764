import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { SelectChangeEvent } from '@mui/material/Select';
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    competencyName: string;
    selectedCompetencyType: string;
    competencyType: string[];
    selectedTargetLevel: string;
    targetLevel: string[];
    dictionaryDescription: string;
    saveDicClicked: boolean;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class DictionaryCreationController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            competencyName: "",
            selectedCompetencyType: "",
            competencyType: ["Core competency", "Basic Competency", "Competency"],
            selectedTargetLevel: "",
            targetLevel: ["Basic", "Intermediate", "Advanced"],
            dictionaryDescription: "",
            saveDicClicked: false
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

    }
    handleSaveCompDictionary = () => {
        this.setState({ saveDicClicked: true })
        const errorInCompName = !this.state.competencyName;
        const errorInCompType = !this.state.selectedCompetencyType;
        const errorInTargetLevel = !this.state.selectedTargetLevel;
        const errorInDiscription = !this.state.dictionaryDescription;

        if (errorInCompName ||
            errorInCompType ||
            errorInTargetLevel ||
            errorInDiscription
        ) {
            return;
        }

        this.navigateToViewDictionary();
    }

    handleCompetencyNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ competencyName: event.target.value })
    }

    handleCompetencyTypeChange = (event: SelectChangeEvent<string>) => {
        this.setState({ selectedCompetencyType: event.target.value });
    }

    handleTargetLevelChange = (event: SelectChangeEvent<string>) => {
        this.setState({ selectedTargetLevel: event.target.value });
    }

    handleDicDescription = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ dictionaryDescription: event.target.value.length > 1000 ? this.state.dictionaryDescription : event.target.value })
    }

    navigateToDashboard = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "CompetencyDictionaryDashboard");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    navigateToViewDictionary = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ViewDictionary");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    // Customizable Area End
}