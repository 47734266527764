import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { backArrow, uploadImage, uploadedFile, more, successImg, emptyDashboardImg } from "./assets";
import {
  Box,
  Grid,
  styled,
  Typography as MUITypography,
  Divider,
  Input as MUIInput,
  Card,
  CardContent,
  Select,
  InputBase,
  MenuItem,
  Button as MUIButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  LinearProgress
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// Customizable Area End

import CfchatgptcompetencymapgenerationController, {
  Props,
  configJSON,
} from "./CfchatgptcompetencymapgenerationController";

export default class Cfchatgptcompetencymapgeneration extends CfchatgptcompetencymapgenerationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  progressScreen = () => {
    return (
      <Wrapper>
        <Box className="compItemMainBox">
          <Grid container className="compCardContainer" justifyContent={"flex-start"}>
            <Grid item xs={12} className="compCardItem">
              <Card className="compCardDescBox">
                <CardContent className="compCardContentPadding">
                  <Grid container>
                    <EmptyWrapper>
                      <Grid container className="gridContainer">
                        <Grid item xs={12}>
                          <Box className="compNoDataImageBox">
                            <img src={emptyDashboardImg.default} />

                            <Box className="dotsProgress">
                              {[0, 1, 2, 3, 4, 5, 6].map((obj, index) => <Box key={index} style={{
                                width: "7.5px", height: "7.5px",
                                borderRadius: "50%", backgroundColor: "#059669",
                                transform: `${this.handleCondition(index === this.state.active, "scale(1.5)", "scale(1)")}`
                              }}></Box>)}
                            </Box>

                            <Box width="50%" mt={2}>
                              <LinearProgress
                                variant="determinate"
                                value={this.state.exportProgress}
                                className="progreassbarStyle"
                              />
                            </Box>

                            <MUITypography className="progressCompleteText">
                              {this.state.exportProgress}% Complete, Analysing job roles...
                            </MUITypography>

                            <MUITypography className="hangTightText">
                              Hang tight! We're building your competency framwork based on your inputs...
                            </MUITypography>
                            <MUITypography className="hangTightSubText">
                              This process may take a few minutes. Please do not close your browser or navigate away.
                            </MUITypography>
                          </Box>
                        </Grid>
                      </Grid>
                    </EmptyWrapper>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Wrapper>
    )
  }

  successUserCreationDialog = () => {
    return (
      <Dialog
        open={this.state.openSuccessComp}
        onClose={this.handleCloseSuccessComp}
        PaperProps={{
          sx: {
            direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
            borderRadius: "8px 8px 32px 8px",
            overflow: 'hidden',
          }
        }}
      >
        <DialogTitle>
          <Wrapper>
            <MUITypography className="successCompDialogTitle">
              {this.state.isEdit ? "Competency Updated" : "Competency Created"}
            </MUITypography>
            <IconButton
              aria-label="close"
              data-test-id="close_competency_success"
              onClick={this.handleCloseSuccessComp}
              className="successCompDialogClose"
              sx={{
                right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30
              }}
            >
              <CloseIcon />
            </IconButton>
          </Wrapper>
        </DialogTitle>
        <Divider sx={{ color: "#E2E8F0" }} />
        <DialogContent>
          <Wrapper>
            <Box className="successCompImageBox">
              <img src={successImg.default} alt="Success Image" height={144} width={144} />
            </Box>
          </Wrapper>
          <MUITypography
            sx={{
              fontSize: "20px",
              fontWeight: 700,
              fontFamily: "Urbanist",
              padding: "14px 34px",
              lineHeight: "28px",
              color: "#0F172A",
              textAlign: "center"
            }}>
            {this.state.isEdit ?
              "Company Competency Updated Successfully" :
              "Company Competency Created Successfully"
            }
          </MUITypography>
          <MUITypography
            sx={{
              fontWeight: 400,
              lineHeight: "26px",
              fontFamily: "Urbanist",
              padding: "0px 68px",
              fontSize: "18px",
              color: "#0F172A",
              textAlign: "center"
            }}>
            {
              this.state.isEdit ? (
                <>
                  The competency for <span style={{ fontWeight: 700 }}>Organisational Awareness</span> has been updated successfully.
                </>
              ) : (
                <>
                  The competency for <span style={{ fontWeight: 700 }}>Organisational Awareness</span> has been added successfully.
                </>
              )
            }

          </MUITypography>

        </DialogContent>
        <Divider />
        <DialogActions>
          <Wrapper>
            <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "compButtonsBox" : "compButtonsBoxArabic"}`}>
              <MUIButton
                color="primary"
                variant="contained"
                onClick={this.handleCloseSuccessComp}
                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "viewCompButton" : "viewCompButtonArabic"}`}
              >
                Done
              </MUIButton>
            </Box>
          </Wrapper>
        </DialogActions>
      </Dialog>
    )
  }

  competencyType = () => {
    const error = this.state.generateCompClicked && !this.state.selectedCompetencyType && configJSON.errorText || "";
    return (
      <Wrapper>
        <Box className="errorStyleMainbox">
          <MUITypography className="compdInputLabel">
            Competency Type <span>*</span>
          </MUITypography>
          <MUITypography className="errorStyle">
            {error}
          </MUITypography>
        </Box>
        <Select
          className={`${error ? "errorClass" : ""} dropDownStyle`}
          data-test-id="competency_type"
          inputProps={{
            icon: {
              left: 0,
            },
          }}
          displayEmpty
          variant="outlined"
          value={this.state.selectedCompetencyType}
          renderValue={
            this.state.selectedCompetencyType
              ? undefined
              : () => (
                <div className="dropDownStyleplaceHolder">
                  Choose Competency Type
                </div>
              )
          }
          IconComponent={KeyboardArrowRightIcon}
          onChange={this.handleCompetencyTypeChange}
          input={<InputBase />}
        >
          {this.state.competencyType?.map((comp) => (
            <MenuItem key={comp.id} value={comp.id}
              sx={{
                fontSize: "16px",
                fontFamily: "Urbanist",
                fontWeight: 400,
                margin: "4px"
              }}>
              {comp.name}
            </MenuItem>
          ))}
        </Select>
      </Wrapper>
    )
  }

  jobTitle = () => {
    const error = this.state.generateCompClicked && !this.state.jobTitle && configJSON.errorText || "";
    return (
      <Wrapper>
        <Box className="errorStyleMainbox">
          <MUITypography className="compdInputLabel">
            Job Titles <span>*</span>
          </MUITypography>
          <MUITypography className="errorStyle">
            {error}
          </MUITypography>
        </Box>
        <MUIInput
          data-test-id="job_title"
          className={`${error ? "errorClass" : ""} dropDownStyle`}
          disableUnderline
          placeholder="Choose Job Titles"
          value={this.state.jobTitle}
          onChange={this.handleJobTitleChange}
          autoComplete="off"
        />

      </Wrapper>
    )
  }

  compDescription = () => {
    return (
      <Wrapper>
        <Box className="errorStyleMainbox">
          <MUITypography className="compdInputLabel">
            Description <span>*</span>
          </MUITypography>
        </Box>
        <textarea
          data-test-id="comp_description"
          className="compDescInputField"
          placeholder="Write about your job..."
          value={this.state.compDescription}
          onChange={(event) => this.handleCompDescription(event)}
        />
        <MUITypography className="companyCultureLimit">{this.state.compDescription?.length || 0}/5000</MUITypography>
      </Wrapper>
    )
  }

  jdImageGrid = () => {
    return (
      <Grid item xs={12} sm={2} md={1.5} className="compJdInnerGrid">
        <img
          data-test-id="upload_image"
          src={(this.state.selectedFile || (this.state.isEdit && this.state.uploadedFile)) ? uploadedFile.default : uploadImage.default}
          alt="upload Image"
          onClick={this.handleImageClick}
        />
      </Grid>
    )
  }

  jdFileNameAndSizeGrid = () => {
    return (
      <Grid item xs={12} sm={9} md={9.5} sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: {
          xs: 'center',
          sm: 'flex-start',
        },
        textAlign: {
          xs: 'center',
          sm: 'left',
        }
      }}>
        {this.state.isEdit ?
          <>
            <MUITypography className="jdUploadText">
              {this.isEditJdTemplate()}
            </MUITypography>
            <MUITypography className="jdUploadSubText">
              {this.isEditJdDocumentSize()}
            </MUITypography>
          </> :
          <>
            <MUITypography className="jdUploadText">
              {this.state.selectedFile ? this.state.selectedFile?.name : "Upload PDFs or Word documents"}
            </MUITypography>
            <MUITypography className="jdUploadSubText">
              {this.state.selectedFile
                ? `${(this.state.selectedFile?.size / 1024).toFixed(2)} KB`
                : "You can upload PDFs or Word documents that provide additional details or guidelines related to this competency."}
            </MUITypography>
          </>
        }
      </Grid>
    )
  }

  jdTemplates = () => {
    const errorInFile = (!this.state.isEdit && !(this.state.selectedFile || this.state.compDescription)) ||
      (this.state.isEdit &&
        (!((this.state.uploadedFile || "").length || this.state.selectedFile || this.state.compDescription)))

    const errorRequired = this.state.generateCompClicked && errorInFile &&
      "Please provide either a description or an attachment. At least one is required." || "";
    const errorMB = this.state.fileError.length > 0 && this.state.fileError || "";
    return (
      <Wrapper>
        <Box className="errorStyleMainbox">
          <MUITypography className="compdInputLabel">
            Upload Job Discriptions <span>*</span>
          </MUITypography>
        </Box>
        <Box className={`${(errorRequired.length > 0 || errorMB.length > 0) ? "errorClass" : ""} jdMainBox`}>
          <Grid container alignItems={"center"} spacing={1}>
            {this.jdImageGrid()}

            {this.jdFileNameAndSizeGrid()}

            <Grid item xs={12} sm={1} className="deleteJdImg">
              <img src={more.default}
                data-test-id="delete_template"
                alt="Delete document"
                onClick={this.handleFileDelete}
              />
            </Grid>
          </Grid>
          <input
            style={{ display: 'none' }}
            id="upload-jd-input"
            type="file"
            accept=".pdf,.doc,.docx"
            data-test-id="upload_template"
            onChange={this.handleFileUpload}
          />
        </Box>
        <MUITypography className="errorStyle">
          {errorMB}
          {!errorMB && errorRequired}
        </MUITypography>
      </Wrapper>
    )
  }

  cancelCompButton = () => {
    return (
      <Wrapper>
        <MUIButton
          className="compCancelBtn"
          data-test-id="cancel_comp"
          onClick={this.navigateToDashboard}
        >
          <MUITypography className="compCancelText">
            Cancel
          </MUITypography>
        </MUIButton>
      </Wrapper>
    )
  }

  generateCompetencyButton = () => {
    return (
      <Wrapper>
        <MUIButton
          data-test-id="generate_comp"
          className="generateCompBtn"
          onClick={this.handleGenerateComp}
        >
          <MUITypography className="generateCompText">
            {this.state.isEdit ? "Update Competency" : "Generate Competency"}

          </MUITypography>
        </MUIButton>
      </Wrapper>
    )
  }
  companyFormHeader = () => {
    return (
      <Wrapper>
        <Box className="compItemMainBox">
          <Grid container className="compCardContainer" justifyContent={"flex-start"}>
            <Grid item xs={12} className="compCardItem">
              <Card className="compCardDescBox">
                <CardContent className="compCardContentPadding">
                  <Grid container>
                    <Grid item xs={12} className="compOrgDetailPadding">
                      <MUITypography className="compCardTitle">
                        New Competency
                      </MUITypography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3.5} className="compContentPadding">
                    <Grid item xs={12} md={6}>
                      {this.competencyType()}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {this.jobTitle()}
                    </Grid>
                    <Grid item xs={12}>
                      {this.compDescription()}
                    </Grid>

                    <Grid item xs={12}>
                      {this.jdTemplates()}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {this.cancelCompButton()}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {this.generateCompetencyButton()}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Wrapper>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <DashboardHeader selectedMenu="Competencies" navigation={this.props.navigation} id={this.props.id}>
        <MainWrapper>
          <Grid container justifyContent={"center"}>
            <Grid item xs={11}>
              <Grid container spacing={1} alignItems={"center"}>
                <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}>
                  <img src={backArrow.default}
                    alt="Back Arrow"
                    className="backArrowImg"
                    data-test-id="back_to_dashboard"
                    onClick={this.state.progrssbarScreen ? this.navigateToCreateComp : this.navigateToDashboard}
                  />
                </Grid>
                <Grid item xs={10.5} sm={11} md={11.2} lg={11.5}>
                  <MUITypography className="compTitle">
                    {this.compTitle()}
                  </MUITypography>
                </Grid>
              </Grid>
              <Grid container spacing={1} className="compMainHeaderGrid">
                <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                  {this.state.progrssbarScreen ?
                    this.progressScreen() :
                    this.companyFormHeader()
                  }
                  {this.successUserCreationDialog()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MainWrapper>
      </DashboardHeader>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const EmptyWrapper = styled(Box)({
  width: "100%",
  paddingBottom: "30px",
  height: "calc(100vh - 180px)",
  "& .createCompetencyButton": {
    marginTop: "24px",
    padding: "8px 90px",
    height: "58px",
    maxWidth: "400px",
    borderRadius: "4px",
    background: "#044352",
  },
  "& .createCompDictionaryButton": {
    marginTop: "24px",
    padding: "8px 50px",
    maxWidth: "400px",
    height: "58px",
    borderRadius: "4px",
    border: "1px solid #94A3B8"
  },
  "& .compNoDataImageBox": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .compNoDataNextText": {
    lineHeight: "26px",
    color: "#8A96A8",
    width: "50%",
    fontWeight: 700,
    fontFamily: "Urbanist",
    fontSize: "18px",
    textAlign: "center"
  },
  "& .gridContainer": {
    height: "100%"
  },
  "& .compDectionaryText": {
    fontWeight: 700,
    fontFamily: "Urbanist",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000104",
    textTransform: "none"
  },
  "& .compNoDataMainText": {
    fontFamily: "Urbanist",
    color: "#000104",
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "40px",
  },
  "& .compCreateText": {
    fontWeight: 700,
    fontFamily: "Urbanist",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    textTransform: "none"
  }
})

export const Wrapper = styled(Box)({
  width: "100%",
  "& .compCardDescBox": {
    width: "55vw",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
    margin: "4vh 3vw",
    "@media(max-width:900px)": {
      width: "80vw"
    },
  },
  "& .compCardItem": {
    scrollSnapAlign: "center",
    flex: "0 0 auto",
  },
  "& .compItemMainBox": {
    overflow: "hidden",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  "& .compOrgDetailPadding": {
    padding: "30px 56px 30px 35px"
  },
  "& .compCardContentPadding": {
    padding: "0px 0px 42px 0px"
  },
  "& .compContentPadding": {
    padding: "30px 56px 0px 35px"
  },
  "& .successCompDialogClose": {
    top: 16,
    position: 'absolute',
    color: "#334155"
  },
  "& .viewCompButton": {
    padding: "16px 24px",
    fontWeight: 700,
    width: "30%",
    height: "56px",
    fontFamily: "Urbanist",
    fontSize: "16px",
    gap: "8px",
    borderRadius: "8px",
    opacity: "0px",
    marginLeft: "16px",
    background: "#044352",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#044352"
    },
  },
  "& .viewCompButtonArabic": {
    height: "56px",
    gap: "8px",
    borderRadius: "8px",
    opacity: "0px",
    fontSize: "16px",
    fontWeight: 700,
    width: "120px",
    fontFamily: "Urbanist",
    padding: "16px 39.5px 16px 36.5px",
    marginRight: "16px",
    background: "#044352",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#044352"
    },
  },
  "& .compButtonsBox": {
    margin: "16px 0px",
    paddingRight: "16px",
    display: "flex",
    justifyContent: "flex-end"
  },
  "& .compButtonsBoxArabic": {
    display: "flex",
    justifyContent: "flex-end",
    paddingLeft: "16px",
    margin: "16px 0px"
  },
  "& .successCompImageBox": {
    display: "flex",
    padding: "10px 0px",
    justifyContent: "center"
  },
  "& .successCompDialogTitle": {
    fontFamily: "Urbanist",
    color: "#1E1E1E",
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 700,
    margin: "8px 16px"
  },
  "& .compCardTitle": {
    color: "#0444352",
    fontFamily: "Urbanist",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "24px"
  },
  "& .compDescInputField": {
    borderRadius: "8px",
    boxShadow: "0px 1px 2px 0px #1018280D",
    fontSie: "16px",
    fontFamily: 'Urbanist',
    border: '1px solid #CBD5E1',
    fontWeight: 400,
    lineHight: "24px",
    minHeight: "98px",
    height: "auto",
    padding: "12px 16px",
    width: '100%',
    color: "#475569",
    "&:focus": {
      borderColor: "#CBD5E1",
      outline: "none",
    }
  },
  "& .MuiSelect-icon": {
    transform: 'none !important',
  },
  "& .errorStyleMainbox": {
    display: "flex",
    justifyContent: "space-between"
  },
  "& .generateCompText": {
    fontSize: "16px",
    color: "#FFFFFF",
    fontFamily: "Urbanist",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none"
  },
  "& .generateCompBtn": {
    borderRadius: "4px",
    height: "58px",
    padding: "10px 16px 10px 16px",
    border: "1px solid #94A3B8",
    background: "#044352",
    width: "100%",
    marginTop: "22px",
  },
  "& .compCardContainer": {
    overflowX: "auto",
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    scrollSnapType: "x mandatory",
    scrollBehavior: "smooth",
    padding: "0px 8px",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  "& .errorStyle": {
    fontFamily: "Urbanist",
    color: "#F87171",
    fontSize: "16px",
    fontWeight: 400,
    fontStyle: "italic",
    display: "flex",
    justifyContent: "flex-end"
  },
  "& .jdMainBox": {
    padding: "20px 5px",
    borderRadius: "8px",
    display: "flex",
    border: "1px solid #CBD5E1",
    alignItems: "center"
  },
  "& .compJdInnerGrid": {
    justifyContent: "center",
    display: "flex",
  },
  "& .deleteJdImg": {
    display: "flex",
    justifyContent: "center"
  },
  "& .compCancelBtn": {
    width: "100%",
    height: "58px",
    padding: "10px 16px 10px 16px",
    borderRadius: "4px",
    border: "1px solid #94A3B8",
    marginTop: "22px",
    "&:hover": {
      background: "none"
    }
  },
  "& .compCancelText": {
    fontWeight: 700,
    fontFamily: "Urbanist",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    textTransform: "none"
  },
  "& .jdUploadSubText": {
    fontWeight: 400,
    lineHeight: "24px",
    fontFamily: "Urbanist",
    fontSize: "16px",
    color: "#64748B"
  },
  "& .compdInputLabel": {
    marginBottom: "10px",
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: 700,
    linHheight: "22px",
    color: "#344054"
  },
  "& .jdUploadText": {
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Urbanist",
    fontWeight: 700,
    color: "#0F172A"
  },
  "& .errorClass": {
    border: '1px solid #F87171 !important',
  },
  "& .companyCultureLimit": {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Urbanist",
  },
  "& .dropDownStyle": {
    backgroundColor: 'white',
    height: '66px',
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
    fontSie: '16px',
    fontWeight: 700,
    lineHight: "24px",
    fontFamily: 'Urbanist',
    padding: '10px 12px',
    width: '100%',
    color: "#044352"
  },
  "& .dropDownStyleplaceHolder": {
    color: "#DCDCDC",
    fontSize: 16,
    fontWeight: 400
  } as React.CSSProperties,
  "& input::placeholder, textarea::placeholder": {
    color: "#cccccc"
  },
  "& .dotsProgress": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "30px",
    marginBottom: "20px",
    gap: "7.5px",
    "@media (max-width: 768px)": {
      gap: "5px",
    },
    "@media (max-width: 480px)": {
      gap: "3px",
    },
  },
  "& .progreassbarStyle": {
    height: 10,
    borderRadius: 4,
    backgroundColor: '#e0e0e0',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#059669',
    },
  },
  "& .progressCompleteText": {
    color: "#059669",
    marginTop: "8px",
    fontFamily: "Urbanist",
    padding: "0px 58px",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
  },
  "& .hangTightText": {
    color: "#0F172A",
    marginTop: "66px",
    fontFamily: "Urbanist",
    padding: "0px 58px",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "26px",
    textAlign: "center",
  },
  "& .hangTightSubText": {
    color: "#0F172A",
    marginTop: "8px",
    fontFamily: "Urbanist",
    padding: "0px 58px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "26px",
    textAlign: "center"
  }
})

export const MainWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  marginTop: "42px",
  paddingBottom: "30px",
  "& .compTitle": {
    fontWeight: 600,
    fontFamily: "Urbanist",
    fontSize: "25px",
    lineHeight: "30px",
    color: "#000104",
    marginLeft: "12px"
  },
  "& .backArrowImg": {
    paddingRight: "12px"
  },
  "& .compMainHeaderGrid": {
    marginTop: "20px"
  },
})

// Customizable Area End
