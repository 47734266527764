import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { SelectChangeEvent } from '@mui/material/Select';
import { removeStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedDate: null | string;
    tempDate: null | string;
    calendarOpen: boolean;
    searchText: string;
    selectedStatus: string;
    competencyStatus: string[];
    anchorEl: HTMLElement | null;
    selectedRowId: string | null;
    checkedRow: boolean;
    createCompDropdown: boolean;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CompetencyDashboardController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            selectedDate: null,
            tempDate: null,
            calendarOpen: false,
            searchText: "",
            selectedStatus: "",
            competencyStatus: ["Active", "Inactive"],
            anchorEl: null,
            selectedRowId: null,
            checkedRow: false,
            createCompDropdown: false
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

    }

    toggleDropdown = () => {
        this.setState((prevState) => ({
            createCompDropdown: !prevState.createCompDropdown,
        }));
    };

    handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ checkedRow: event.target.checked });
    };

    handleCompetencyStatus = (event: SelectChangeEvent<string>, id: string, status: boolean) => {
        this.setState({ selectedStatus: event.target.value })
    }

    handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
            selectedRowId: null
        });
    };

    handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const searchValue = event.target.value;
        this.setState({ searchText: searchValue });
    };

    handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter') {
            const searchValue = this.state.searchText;
            this.setState({ searchText: searchValue });
        }
    };

    handleCondition = (condition: any, truePart: any, falsePart: any) => {
        return condition ? truePart : falsePart
    }

    openCalendar = () => {
        this.setState((prevState) => ({ calendarOpen: !prevState.calendarOpen }));
    };

    handleCalendarCancelBtn = () => {
        this.setState({ tempDate: null, calendarOpen: false });
    };
    handleCalendarSaveBtn = () => {
        if (this.state.tempDate) {
            this.setState({
                selectedDate: this.state.tempDate,
                tempDate: null,
                calendarOpen: false,
            });
        }
    };

    handleDateChange = (newDate: any) => {
        this.setState({ tempDate: newDate });
    };

    formatDateRange = (dateRange: any) => {
        if (Array.isArray(dateRange) && dateRange.length === 2) {
            const [startDate, endDate] = dateRange;
            return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
        }
        return 'Select Start Date - End Date';
    };

    navigateToCreateComp = async () => {
        await removeStorageData("competencyId");
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Cfchatgptcompetencymapgeneration");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    navigateToDictionary = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "CompetencyDictionaryDashboard");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    // Customizable Area End
}