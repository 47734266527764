import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Button,
    Card,
    CardContent,
    Divider
} from "@mui/material";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import { backArrow } from "./assets";
// Customizable Area End

import ViewDictionaryController, {
    Props,
} from "./ViewDictionaryController";

export default class ViewDictionary extends ViewDictionaryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    viewCompetencyName = () => {
        return (
            <Wrapper>
                <Typography className="dicViewLabel">
                    Competency Name <span>*</span>
                </Typography>
                <Typography className="dicDescField">
                    Organisational Awareness
                </Typography>
                <Divider sx={{ marginTop: "22px" }} />
            </Wrapper>
        )
    }

    viewCompetencyType = () => {
        return (
            <Wrapper>
                <Typography className="dicViewLabel">
                    Competency Type <span>*</span>
                </Typography>
                <Typography className="dicDescField">
                    Core Competencies
                </Typography>
            </Wrapper>
        )
    }

    viewTargetLevel = () => {
        return (
            <Wrapper>
                <Typography className="dicViewLabel">
                    Target Level <span>*</span>
                </Typography>

                <Typography className="dicDescField">
                    Advance
                </Typography>
            </Wrapper>
        )
    }

    dicBehaviour = () => {
        const dicDescription = `1. Understanding Organisational Structure:
        Knowledge of Hierarchies: Understands the formal reporting lines, key decision-makers, and the overall organisational chart.
        Navigating Informal Networks: Recognises and leverages informal networks and relationships within the organisation to influence decisions and actions.
        Departmental Functions: Has a clear understanding of the roles and responsibilities of different departments and how they contribute to the organisation’s goals.
        
        2. Awareness of Organisational Culture:
        Cultural Sensitivity: Understands the organisation’s values, norms, and unwritten rules, and how they influence behaviour and decision-making.
        Adaptability: Adjusts behaviour and communication style to align with the organisational culture and to effectively engage with different stakeholders.
        
        3. Recognising Power Dynamics:
        Influence and Authority: Identifies who holds formal and informal power and understands how decisions are made within the organisation.
        Strategic Alliances: Builds and maintains relationships with key influencers and stakeholders to gain support for initiatives and projects.`
        return (
            <Wrapper>
                <Typography className="dicViewLabel">
                    Behaviour <span>*</span>
                </Typography>
                <Typography className="dicDescViewField">
                    {dicDescription.split('\n').map((text, index) => (
                        <React.Fragment key={index}>
                            {text}
                            <br />
                        </React.Fragment>
                    ))}
                </Typography>
            </Wrapper>
        )
    }

    viewDictionaryHeader = () => {
        return (
            <Wrapper>
                <Box className="dicItemMainBox">
                    <Grid container justifyContent={"flex-start"} className="dicCardContainer" >
                        <Grid item xs={12} className="dicCardItem">
                            <Card className="dicCardDescBox">
                                <CardContent className="cardContentPadding">
                                    <Grid container>
                                        <Grid item xs={12} className="dicDetailPadding">
                                            <Typography className="dicCardTitle">
                                                Dictionary details
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3.5} className="contentPadding">
                                        <Grid item xs={12}>
                                            {this.viewCompetencyName()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.viewCompetencyType()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.viewTargetLevel()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.dicBehaviour()}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Competencies" id={this.props.id} navigation={this.props.navigation}>
                <MainWrapper>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={11}>
                            <Grid container alignItems={"center"} spacing={1}>
                                <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}>
                                    <img src={backArrow.default}
                                        alt="Back Arrow"
                                        className="backArrowImg"
                                        data-test-id="navigate_to_create_dic"
                                    />
                                </Grid>
                                <Grid item xs={10.5} sm={5} md={5.2} lg={5.5}>
                                    <Typography className="dicTitle">
                                        Competency Dictionary
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Grid container spacing={2} justifyContent={"ceneter"}>
                                        <Grid item xs={12} sm={5} md={5} lg={4} className="editDicMainBox">
                                            <Button
                                                className="deleteButton"
                                                data-test-id="delete_dic_dialog"
                                            >
                                                <Typography className="deleteDetailText">
                                                    Delete Details
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={6} lg={4.8} className="editDicMainBox">
                                            <Button
                                                className="editDicButton"
                                                data-test-id="edit_dictionary"
                                            >
                                                <Typography
                                                    className="editDicText">
                                                    Edit Competency Dictionary
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                                    {this.viewDictionaryHeader()}
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </MainWrapper >

            </DashboardHeader >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const MainWrapper = styled(Box)({
    paddingBottom: "30px",
    width: "100%",
    marginTop: "42px",
    display: "flex",
    justifyContent: "center",
    "& .backArrowImg": {
        paddingRight: "12px"
    },
    "& .dicTitle": {
        fontWeight: 600,
        fontSize: "25px",
        lineHeight: "30px",
        fontFamily: "Urbanist",
        color: "#000104",
        marginLeft: "12px"
    },
    "& .editDicMainBox": {
        display: "flex",
        justifyContent: "flex-end",
        "@media(max-width:600px)": {
            justifyContent: "flex-start",
            marginTop: "10px"
        }
    },
    "& .deleteButton": {
        padding: "10px 10px",
        height: "58px",
        borderRadius: "4px",
        width: "100%",
        border: "2px solid #94A3B8",
        "&:hover": {
            background: "none"
        },
        "@media(max-width:599px)": {
            width: "60%"
        }
    },
    "& .deleteDetailText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        lineHeight: "24px",
        fontSize: "16px",
        color: "#044352",
        textTransform: "none"
    },
    "& .editDicButton": {
        padding: "10px 10px",
        borderRadius: "4px",
        width: "100%",
        height: "58px",
        border: "1px solid #94A3B8",
        background: "#044352",
        "@media(max-width:599px)": {
            width: "60%"
        }
    },
    "& .editDicText": {
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Urbanist",
        color: "#FFFFFF",
        textTransform: "none"
    },
})
export const Wrapper = styled(Box)({
    width: "100%",
    "& .dicViewLabel": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "14px",
        color: "#344054",
        linHheight: "22px",
    },
    "& .dicDescField": {
        backgroundColor: 'white',
        fontSie: '16px',
        fontWeight: 700,
        lineHight: "24px",
        fontFamily: 'Urbanist',
        padding: '10px 0px',
        width: '100%',
        color: "#475569"
    },
    "& .dicDescViewField": {
        fontSie: "16px",
        fontFamily: 'Urbanist',
        fontWeight: 400,
        lineHight: "24px",
        padding: "12px 0px",
        width: '100%',
        color: "#475569"
    },
    "& .dicCardDescBox": {
        width: "55vw",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        margin: "4vh 3vw",
        "@media(max-width:900px)": {
            width: "80vw"
        },
    },
    "& .dicCardContainer": {
        overflowX: "auto",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        scrollSnapType: "x mandatory",
        scrollBehavior: "smooth",
        padding: "0px 8px",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    "& .dicDetailPadding": {
        padding: "30px 56px 30px 35px"
    },
    "& .dicItemMainBox": {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        overflow: "hidden"
    },
    "& .dicCardItem": {
        scrollSnapAlign: "center",
        flex: "0 0 auto"
    },
    "& .cardContentPadding": {
        padding: "0px 0px 42px 0px"
    },
    "& .contentPadding": {
        padding: "30px 56px 0px 35px"
    },
    "& .dicCardTitle": {
        fontWeight: 600,
        fontFamily: "Urbanist",
        fontSize: "20px",
        lineHeight: "24px",
        color: "#0444352"
    },
})
// Customizable Area End